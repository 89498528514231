import classNames from "classnames"
import styles from "./sidepanel.module.css"
import { Button } from "../../../components/button/Button"
import { Select } from "../../../components/form/select"
import { useCallback, useEffect, useMemo, useState } from "react"
import { getUsers } from "../../../fetch/users"
import { getAllProjects } from "../../../fetch/projects"
import { useFetchHook } from "../../../lib/hooks/fetchHook"
import { Input } from "../../../components/form/input"
import { Portal } from "../../../components/portal/Portal"
import { getDistricts } from "../../../fetch/districts"
import { Combobox } from "@/components/combobox/Combobox"
import { Label } from "@/components/ui/label"

type AvailableFilters =
  | "statuses"
  | "from"
  | "to"
  | "workers"
  | "projects"
  | "status"
  | "districts"

export const Filters = ({
  onFiltersChange,
  availableFilters = ["from", "to", "workers", "projects"],
  show,
  initialFilters,
}: {
  onFiltersChange: (filters: Record<string, any>) => void
  availableFilters: AvailableFilters[]
  show: boolean
  initialFilters?: Record<string, string | string[]>
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { data: workers } = useFetchHook<{ items: any[] }>(getUsers, {
    defaultValue: { items: [] },
  })
  const { data: districts } = useFetchHook(getDistricts, {
    defaultValue: [],
  })
  const { data: projects } = useFetchHook(getAllProjects, { defaultValue: [] })
  const [filters, setFilters] = useState(initialFilters ?? {})

  const workerItems = useMemo(() => {
    return [{ id: undefined, name: "Visi Darbinieki" }, ...workers?.items]
  }, [workers])

  const projectItems = useMemo(() => {
    return [{ id: undefined, name: "Visi Projekti" }, ...projects]
  }, [projects])

  const districtsItems = useMemo(() => {
    return [{ id: undefined, name: "Visi Iecirkņi" }, ...districts]
  }, [districts])

  useEffect(() => {
    onFiltersChange(filters)
  }, [filters, onFiltersChange])

  useEffect(() => {
    if (!show) setIsOpen(false)
  }, [show])

  const selectMultipleOptions = useCallback(
    (field: string, options?: any[]) => {
      const opts = [...(options ?? [])]

      if (opts.includes(undefined)) {
        setFilters((f) => ({ ...f, [field]: [] }))
        return
      }
      setFilters((f) => ({ ...f, [field]: opts }))
    },
    [setFilters],
  )

  return (
    show && (
      <>
        <Portal id="tabs-right">
          <Button onClick={() => setIsOpen(true)}>Filtrēt</Button>
        </Portal>
        <div
          className={classNames(
            styles.sidepanel,
            styles.small,
            isOpen ? styles.open : "",
          )}
        >
          <div className={styles.wrap}>
            <div className={styles.close}>
              <Button variant="inline" onClick={() => setIsOpen(false)}>
                Aizvērt
              </Button>
            </div>
            <div className={styles.title}>Filtrēt</div>
            <div className={styles.formWrap}>
              {availableFilters.includes("from") && (
                <Input
                  type="date"
                  label="Datums no:"
                  min="2024-07-07"
                  value={filters.from}
                  onChange={(e) =>
                    setFilters((f) => ({ ...f, from: e.target.value }))
                  }
                />
              )}
              {availableFilters.includes("to") && (
                <Input
                  type="date"
                  label="Datums līdz:"
                  value={filters.to}
                  min="2024-07-07"
                  onChange={(e) =>
                    setFilters((f) => ({ ...f, to: e.target.value }))
                  }
                />
              )}
              {availableFilters.includes("statuses") && (
                <Select
                  label="Statuss"
                  options={[
                    { id: undefined, name: "Visi statusi" },
                    { id: 1, name: "Pieejams" },
                    { id: 2, name: "Sākts" },
                    { id: 3, name: "Pabeigts" },
                    { id: 4, name: "Kļūda" },
                    { id: 5, name: "Apturēts" },
                    { id: 6, name: "Arhivēts" },
                  ]}
                  value={filters.statuses}
                  onChange={(e) =>
                    setFilters((f) => ({ ...f, statuses: e.target.value }))
                  }
                />
              )}
              {availableFilters.includes("status") && (
                <span>
                  <Label>Status</Label>
                  <Combobox
                    options={[
                      { id: 0, name: "Jāapstirpina" },
                      { id: 1, name: "Apstiprinātie" },
                    ]}
                    onChange={(value) => selectMultipleOptions("status", value)}
                  />
                </span>
              )}
              {availableFilters.includes("workers") && (
                <span>
                  <Label>Darbinieki</Label>
                  <Combobox
                    options={workerItems}
                    onChange={(value) =>
                      selectMultipleOptions("workers", value)
                    }
                  />
                </span>
              )}
              {availableFilters.includes("projects") && (
                <span>
                  <Label>Projekts</Label>
                  <Combobox
                    options={projectItems}
                    onChange={(value) =>
                      selectMultipleOptions("projects", value)
                    }
                  />
                </span>
              )}
              {availableFilters.includes("districts") && (
                <span>
                  <Label>Iecirknis</Label>
                  <Combobox
                    options={districtsItems}
                    onChange={(value) =>
                      selectMultipleOptions("districts", value)
                    }
                  />
                </span>
              )}
              {/*<Select
            label={isBitus ? "Tirgi" : "Process"}
            options={opGroupItems}
            onChange={(e) =>
              setFilters((f) => ({ ...f, opGroups: e.target.value }))
            }
          />
          */}
            </div>
          </div>
        </div>
      </>
    )
  )
}
